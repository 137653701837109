<template>
  <div class="">
    <div class="wechat-env-download download" v-if="getBrowser() === 'wechat'">
      <div class="wechat-env-download-shade"></div>
      <div class="go-browser-guild">
        <div class="tip">点击右上角 <img src="https://cdn.001ppt.cn/wxApp/assets/images/triple-dot.png" alt="">
          跳转至浏览器
        </div>
        <img src="https://cdn.001ppt.cn/wxApp/assets/images/go-sys-browser-guild.png" alt="">
      </div>
      <div class="wechat-env-download-bg"></div>
      <div class="tip">右上角跳转即可下载</div>
      <img style="z-index: 1;width: 50vw;margin-bottom: 16px" src="https://cdn.001ppt.cn/wxApp/assets/images/copy-step-2.png" alt="">

<!--      <div class="btn" @click="$router.back()">点击返回 <van-icon name="arrow"/></div>-->
    </div>



    <template v-else>

      <div v-if="!downloadInfo.captchaBase64 && !downloadInfo.url && !downloadInfo.hasUnlock && !hasLogin"
           class="download">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/tip.svg" alt="" class="icon">
        <div class="tip">
          您当前未登录，登录后立即下载
        </div>
        <div style="display: flex;align-items: center;justify-content: center">
          <div class="btn"
               style="background:#151D36;color:#fff;margin-right:16px;width: 125px;height: 24px;margin-top: 16px"
               @click="$store.commit('SHOW_LOGIN_DIALOG')">立即登录
          </div>
          <router-link class="btn" style="width: 125px;height: 24px;margin-top: 16px" replace
                       :to="`/caseDetail/${$route.query.id}`">方案详情页
            <van-icon name="arrow"/>
          </router-link>
        </div>
      </div>


      <div v-else-if="downloadInfo.hasUnlock === false" class="download">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/tip.svg" alt="" class="icon">
        <div class="tip">
          您还未解锁该方案，将在
          <span class="countdown">{{ countdown < 0 ? 0 : countdown }}</span>
          秒后跳转到
        </div>
        <router-link class="btn" style="width: 125px;height: 24px;margin-top: 16px" replace
                     :to="`/caseDetail/${$route.query.id}`">方案详情页
          <van-icon name="arrow"/>
        </router-link>
      </div>


      <div class="download" v-else-if="downloadInfo.hasUnlock === true && downloadInfo.url">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/success-green.svg" alt="" class="icon">
        <div class="tip">
          正在下载，将在
          <span class="countdown">{{ countdown < 0 ? 0 : countdown }}</span>
          秒内关闭本页面
        </div>
        <router-link class="btn" style="width: 125px;height: 24px;margin-top: 16px" replace
                     :to="`/caseDetail/${$route.query.id}`">方案详情页
          <van-icon name="arrow"/>
        </router-link>
        <div style="font-size: 12px;margin-top: 18px">
          若未开始下载，请点击此处
          <a style="font-size: 12px" :href="downloadInfo.url" class="tip_txt_link">立即下载</a>
        </div>
      </div>


      <div class="download" v-else-if="downloadInfo.hasUnlock === true && downloadInfo.captchaBase64">
        <img :src="downloadInfo.captchaBase64" alt="" style="width: 200px;height: 60px">
        <input type="text" class="verify-code" placeholder="请输入验证码" v-model="verifyCode" @keypress.enter="init"/>
        <div class="btn btn-primary" style="width: 125px;height: 24px;margin-top: 16px" @click="init">确认下载</div>
      </div>

    </template>

  </div>
</template>

<script>
import {planV2Api} from "../../../api/v2/planV2Api";
import {downloadByUrl} from "../../../config/util";

export default {
  mixins: [],

  data() {
    return {
      countdown: 5,
      downloadInfo: {
        url: '',
        captchaBase64: '',
        hasUnlock: ''
      },
      verifyCode: '',
    }
  },


  async mounted() {
    if (this.getBrowser() !== 'wechat') {
      await this.init()
    }
  },


  methods: {

    async init() {
      await this.loadDownloadInfo()
      if (!this.downloadInfo.hasUnlock) {
        return this.startCountdown(true)
      }
      if (this.downloadInfo.hasUnlock && this.downloadInfo.url) {
        downloadByUrl(this.downloadInfo.url)
        this.countdown = 3
        return this.startCountdown(true)
      }
      if (this.downloadInfo.hasUnlock && this.downloadInfo.captchaBase64) {
        this.$toast.info('您的下载过于频繁，请输入验证码以确认')
      }
    },


    async loadDownloadInfo() {
      this.downloadInfo = await planV2Api.findDownloadInfo({
        planId: this.$route.query.id,
        verifyCode: this.verifyCode,
        visitorToken: this.$route.query.visitor
      })

      if (!this.downloadInfo.hasUnlock && !this.downloadInfo.url && !this.downloadInfo.captchaBase64) {
        if (!this.hasLogin) {
          return this.$store.commit('SHOW_LOGIN_DIALOG')
        }
      }
    },


    startCountdown(close) {
      const interval = setInterval(() => {
        this.countdown--
        if (this.countdown <= 0) {
          clearInterval(interval)
          if (close) {
            this.$router.replace('/caseDetail/' + this.$route.query.id).then()
          }
        }
      }, 1000)
    },


  }

}
</script>

<style scoped lang="less">

.download {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  width: 120px;
  height: 120px;
  margin-bottom: 48px;
}

.tip {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151d36;
  font-size: 14px;
  z-index: 1;
}

.countdown {
  color: #999;
  margin: 0 4px;
}

.verify-code {
  margin: 16px 0;
  padding: 8px 16px;
  outline: none;
  text-align: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}

.btn {
  background: #f2f5f7;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  z-index: 1;
}

.btn-primary {
  background: #151D36;
  color: #fff;
}


.go-browser-guild {
  position: fixed;
  right: 20px;
  top: 13px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  z-index: 1;

  .tip {
    transform: translateY(8px);
    padding-right: 6px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #fff;

    img {
      width: 20px;
      height: 20px;
      padding: 0 2px;
      transform: translateY(1px);
    }
  }

  img {
    height: 25px;
    width: 80px;
  }
}

.wechat-env-download {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.wechat-env-download-shade{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #151D36;
  opacity: .9;
  z-index: 0;
}


.wechat-env-download-bg{
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 0;
}
</style>
